import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import UriHelper from '../../../../lib/uri-helper';
import { deleteAllAnnotationsAction } from '../../../../store/Annotations/actions';
import { actionActiveMapCleared } from '../../../../store/Map/SuperMap/actions';
import ActiveStoryProjectBottomSheet, {
    StoryOwnerDetailsType,
} from '../../MapSelection/active-story-project-bottomsheet';
import BottomSheet, { BottomSheetState } from '../../MapSelection/bottom-sheet';
import { DrawPage } from '../../../MapView/Annotations/StoryMaps/draw-story';

interface MobileStoryMapBottomSheetProps {
    page: DrawPage;
    numberOfPages: number;
    owner: StoryOwnerDetailsType;
}

const MobileStoryMapBottomSheet = ({ page, numberOfPages, owner }: MobileStoryMapBottomSheetProps) => {
    const [desiredBottomSheetState, setDesiredBottomSheetState] = useState<BottomSheetState | undefined>(undefined);
    const [currentBottomSheetState, setCurrentBottomSheetState] = useState<BottomSheetState | undefined>(undefined);
    const [isBottomSheetClosing, setIsBottomSheetClosing] = useState<boolean>(false);

    const dispatch = useDispatch();

    useEffect(() => {
        requestAnimationFrame(() => {
            setDesiredBottomSheetState(currentBottomSheetState);
        });
    }, [currentBottomSheetState]);

    const handleCloseBottomSheet = () => {
        setDesiredBottomSheetState(BottomSheetState.close);
        dispatch(actionActiveMapCleared());
        dispatch(deleteAllAnnotationsAction());
        UriHelper.navigateToPath('/');
    };

    useEffect(() => {
        // Add an event listener to listen for the "popstate" event (back button press on mobile)
        window.addEventListener('popstate', handleCloseBottomSheet);

        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('popstate', handleCloseBottomSheet);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!page) return null;

    return (
        <BottomSheet
            setOpenState={desiredBottomSheetState}
            onOpenStateChanged={setCurrentBottomSheetState}
            handleCloseButton={() => handleCloseBottomSheet()}
        >
            <ActiveStoryProjectBottomSheet
                bottomSheetState={currentBottomSheetState || BottomSheetState.close}
                page={page}
                numberOfPages={numberOfPages}
                owner={owner}
                onCloseMap={() => {
                    setDesiredBottomSheetState(BottomSheetState.close);
                    setIsBottomSheetClosing(false);
                }}
                isClosing={isBottomSheetClosing}
            />
        </BottomSheet>
    );
};

export default MobileStoryMapBottomSheet;
