import { ProjectResponse } from '../../../api/api-draw';
import { Action } from '../../root-reducer';
import { actionTypes as at } from './constants';

interface StoreDrawProject {
    drawProjects: ProjectResponse[];
    isFetchedDrawProjects: boolean;

    sharedDrawProjects: ProjectResponse[];
    isFetchedSharedDrawProjects: boolean;

    isUpdatingDrawProject?: boolean;
    isDeletingDrawProject?: boolean;
}

const initialState: StoreDrawProject = {
    drawProjects: [],
    isFetchedDrawProjects: false,

    sharedDrawProjects: [],
    isFetchedSharedDrawProjects: false,

    isUpdatingDrawProject: false,
    isDeletingDrawProject: false,
};

export default (state: StoreDrawProject = initialState, action: Action): StoreDrawProject => {
    switch (action.type) {
        case at.DRAW_PROJECTS_FETCH_SUCCESS:
            return {
                ...state,
                isFetchedDrawProjects: true,
                drawProjects: action.payload,
            };
        case at.DRAW_PROJECTS_SHARED_FETCH_SUCCESS:
            return {
                ...state,
                isFetchedSharedDrawProjects: true,
                sharedDrawProjects: action.payload,
            };
        case at.DRAW_PROJECT_UPDATE:
            return {
                ...state,
                drawProjects: state.drawProjects.map((project) => {
                    if (project.id === action.payload.id) {
                        return action.payload;
                    }
                    return project;
                }),
            };
        case at.DRAW_PROJECT_UPDATING:
            return {
                ...state,
                isUpdatingDrawProject: action.payload,
            };
        case at.DRAW_PROJECT_DELETE:
            return {
                ...state,
                drawProjects: state.drawProjects.filter((project) => project.id !== action.payload),
            };
        case at.DRAW_PROJECT_DELETING:
            return {
                ...state,
                isDeletingDrawProject: action.payload,
            };
        default:
            return state;
    }
};
