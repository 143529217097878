import { useEffect, useState, useCallback } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { Redirect } from 'react-router-dom';
import MobileMapContainer from './components/Mobile/mobile-map-container';
import PrivacyAgreement from './components/PrivacyAgreement/privacy-agreement';
import Terms from './components/PrivacyAgreement/terms';
import LoginRegister from './components/Registration/index';
import { LoginModalMode } from './components/Registration/login-enum';
import LoginRegisterDialog from './components/Registration/login-register-dialog';
import PageError from './components/Shared/page-error';
import { SideDrawerMode } from './store/SideDrawer/model';
import { useDispatch, useSelector } from 'react-redux';
import { selectConfig } from './store/App/selectors';
import Skymap50TermsAndConditions from './components/PrivacyAgreement/skymap50-satellite-terms-and-conditions';
import UriHelper, { useAnnotationQuery, usePositionQuery, useQuery, useSentinelQuery } from './lib/uri-helper';
import { actionFlyTo } from './store/App/actions';
import { actionSentinelSelectAOI, actionSentinelSelectFeature } from './store/Map/Sentinel/actions';
import { selectSentinelSelectedFeature } from './store/Map/Sentinel/selectors';
import { selectLoggedIn } from './store/Account/selectors';
import { actionActiveMapCleared, actionActiveMapFetchById } from './store/Map/SuperMap/actions';
import MobileExplorePage from './components/Mobile/Explore/mobile-explore-page';
import MobileSuggestDesktopNotice from './components/Mobile/Shared/DesktopNotification/mobile-suggest-desktop-notice';
import SoarHelper from './lib/soar-helper';
import MobileProfileExplorePage from './components/Mobile/Profile/mobile-profile-explore-page';
import MobilePermissionNotification from './components/Mobile/Annotations/mobile-permission-notification';
import MobileViewStoryMaps from './components/Mobile/Annotations/StoryMaps/mobile-view-story-maps';
import OAuthCallback from './components/Registration/OAuth2/Shared/oauth-callback';
import StoariesCompetitionTermsAndConditions from './components/PrivacyAgreement/stoaries-competition-terms-and-conditions';
import StoariesCompetitionInstructions from './components/PrivacyAgreement/stoaries-competition-instructions';
import JoinMobile from './components/LandingPage/join-mobile';

const MobileRouteMap = () => {
    const [visitedRoute, setVisitedRoute] = useState(false);
    const config = useSelector(selectConfig);
    const query = useQuery();
    const annotationQuery = useAnnotationQuery();
    const [position, zoom] = usePositionQuery();

    const dispatch = useDispatch();

    const sentinelShareFeature = useSentinelQuery();
    const selectedSentinelFeature = useSelector(selectSentinelSelectedFeature);
    const userLoggedIn = useSelector(selectLoggedIn);

    const handlePositionQueryParameter = useCallback(
        (immediately: boolean) => {
            if (position && zoom) {
                dispatch(actionFlyTo(position, zoom, immediately));
            }
        },
        [position, zoom, dispatch]
    );

    // A link was followed to an explicit location and zoom level eg.  ?pos=-31.22,114.3,3
    useEffect(() => {
        handlePositionQueryParameter(true);
    }, [handlePositionQueryParameter]);

    const setShareFeatures = useCallback(() => {
        if (sentinelShareFeature && !selectedSentinelFeature) {
            dispatch(actionFlyTo(sentinelShareFeature.bbox, undefined, true));
            dispatch(actionSentinelSelectAOI(sentinelShareFeature.bbox));
            dispatch(actionSentinelSelectFeature(sentinelShareFeature));
        }
    }, [dispatch, selectedSentinelFeature, sentinelShareFeature]);

    useEffect(() => {
        setShareFeatures();
    }, [setShareFeatures]);

    const clearMap = () => {
        dispatch(actionActiveMapCleared());
    };

    if (config.BEHAVIOURS.FORCED_LOGIN) {
        if (window.location.pathname.startsWith('/password_reset')) {
            return (
                <Route
                    render={(_) => (
                        <LoginRegisterDialog
                            isOpen={true}
                            initialMode={LoginModalMode.PASSWORD_RESET}
                            onClose={() => UriHelper.navigateToPath('/')}
                        />
                    )}
                />
            );
        }
        if (!userLoggedIn) {
            return <Route render={(_) => <LoginRegister subDomain={config.SUBDOMAIN} />} />;
        }
    }

    return (
        <Switch>
            <Route
                exact
                path="/stoaries-competition-terms-and-conditions"
                render={(_) => <StoariesCompetitionTermsAndConditions />}
            />
            <Route
                exact
                path="/stoaries-competition-instructions"
                render={(_) => <StoariesCompetitionInstructions />}
            />
            <Route path="/join" render={(_) => <JoinMobile />} />
            <Redirect exact path="/home" to="/" />
            <Redirect exact path="/index.php" to="/" />
            <Redirect exact path="/index.html" to="/" />
            <Route
                exact
                path="/"
                render={(_) => {
                    // Handle any old tilelayer links eg. https://soar.earth?tileLayer=3000
                    const oldTileLayerUrlParameter = query.get('tileLayer');
                    if (oldTileLayerUrlParameter) {
                        return <Redirect to={`/maps/${oldTileLayerUrlParameter}`} />;
                    }

                    const basemapParameter = query.get(UriHelper.BASEMAP_URI_KEY);
                    const addBasemapParameter = basemapParameter
                        ? `&${UriHelper.BASEMAP_URI_KEY}=${basemapParameter}`
                        : '';

                    if (annotationQuery) {
                        return <Redirect to={`/maps/?annotations=${annotationQuery}${addBasemapParameter}`} />;
                    }

                    if (visitedRoute) {
                        return <Redirect to={`/maps`} />;
                    }

                    if (SoarHelper.isSoarIOS() || SoarHelper.isSoarAndroid()) {
                        return <Redirect to={`/maps`} />;
                    }

                    return <MobileSuggestDesktopNotice setVisitedRoute={() => setVisitedRoute(true)} />;
                }}
            />
            <Route
                exact
                path="/maps"
                render={(_) => {
                    const isDrawProject = query.get('drawProjectId');
                    if (isDrawProject) {
                        return <MobileMapContainer drawerMode={SideDrawerMode.MAPS} />;
                    }
                    return <MobileExplorePage />;
                }}
            />
            <Route
                exact
                path="/maps/:slug"
                render={(props: RouteComponentProps<{ slug: string }>) => {
                    handlePositionQueryParameter(true);
                    clearMap();
                    const tileLayerId = SoarHelper.extractIdFromMapSlug(props.match.params.slug);
                    if (tileLayerId) {
                        dispatch(actionActiveMapFetchById(tileLayerId, zoom));
                    }
                    return <MobileExplorePage />;
                }}
            />
            <Redirect exact path="/draw" to="/maps" />
            <Route
                exact
                path="/draw/:projectId"
                render={(props: RouteComponentProps<{ projectId: string }>) => {
                    const { projectId } = props.match.params;
                    // #7904 - Remove the pos parameter if present from the URL to prevent it interfering with the stoary map view
                    UriHelper.removeParameterFromUri('pos');
                    return (
                        <MobileViewStoryMaps
                            drawProjectId={Number(projectId)}
                            drawerMode={SideDrawerMode.SOAR_PROJECT}
                        />
                    );
                }}
            />
            <Route exact path="/permission-denied" render={(_) => <MobilePermissionNotification />} />
            <Route
                exact
                path="/mobile/sentinel"
                render={(_) => <MobileMapContainer drawerMode={SideDrawerMode.MOBILE_NATIVE} />}
            />
            <Route
                exact
                path="/satellites/sentinel"
                render={(_) => <MobileMapContainer drawerMode={SideDrawerMode.SATELLITE} />}
            />
            <Route
                exact
                path="/satellites/landsat"
                render={(_) => <MobileMapContainer drawerMode={SideDrawerMode.SATELLITE} />}
            />
            <Route
                exact
                path="/satellites"
                render={(_) => <MobileMapContainer drawerMode={SideDrawerMode.SATELLITE} />}
            />
            <Route
                exact
                path="/satellites/cgstl"
                render={(_) => <MobileMapContainer loginMode={!userLoggedIn ? LoginModalMode.REGISTER : undefined} />}
            />
            <Route
                exact
                path="/login"
                render={(_) => <MobileMapContainer loginMode={!userLoggedIn ? LoginModalMode.LOGIN : undefined} />}
            />
            <Route
                exact
                path="/register"
                render={(_) => <MobileMapContainer loginMode={!userLoggedIn ? LoginModalMode.REGISTER : undefined} />}
            />
            <Route
                exact
                path="/password_reset"
                render={(_) => <MobileMapContainer loginMode={LoginModalMode.PASSWORD_RESET} />}
            />
            <Route
                exact
                path="/forgot_password"
                render={(_) => (
                    <MobileMapContainer loginMode={!userLoggedIn ? LoginModalMode.FORGOT_PASSWORD : undefined} />
                )}
            />
            <Route exact path="/auth/callback" component={OAuthCallback} />
            <Route exact path="/terms" render={(_) => <Terms />} />
            <Route path="/skymap50-terms" render={(_) => <Skymap50TermsAndConditions />} />
            <Route exact path="/privacy" render={(_) => <PrivacyAgreement />} />
            <Redirect from="/browse/:filehash" to="/maps/:filehash" />
            <Redirect exact path="/order/payment" to="/" />
            <Redirect from="/dashboard" to="/" />
            <Redirect exact path="/task_payment" to="" />
            <Redirect exact path="/browse" to="/" />
            <Redirect exact path="/explore" to="/" />
            <Redirect exact path="/my-bookmarks" to={{ pathname: '/maps', search: location.search }} />;
            <Redirect exact path="/my-maps" to={{ pathname: '/maps', search: location.search }} />;
            <Route exact path="/profile/:wallet" render={(_) => <MobileProfileExplorePage />} />
            <Redirect exact path="/profile/:wallet/all-maps" to="/profile/:wallet" />
            <Redirect exact path="/my_profile" to="/" />
            <Redirect exact path="/profile" to="/" />
            <Redirect exact path="/plus" to="/" />
            <Redirect exact path="/pricing" to="/" />
            <Route path="/" render={(_) => <Error404 />} />
        </Switch>
    );
};

const Error404: React.FC = () => {
    return (
        <PageError
            gradient
            background="/assets/backgrounds-large/error-background.jpg"
            credit={{
                avatarUrl: 'https://avatar.soar.earth/ffba502ef2914a1f975b6a7868c722achsf.png/preview',
                location: 'Salt Pans',
                name: 'Kim Shapley',
                profile: 'https://soar.earth/profile/ffba502ef2914a1f975b6a7868c722ac',
            }}
        >
            <h3>Oops!</h3>
            <p>
                It looks like the page you are after
                <br />
                does not exist.
            </p>
            <button onClick={() => window.open('/', '_self')}>BACK TO SOAR</button>
        </PageError>
    );
};

export default MobileRouteMap;
