import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { ProjectResponse, UpdateProjectMetaRequest } from '../../../../../api/api-draw';
import { selectActiveAllMap } from '../../../../../store/Map/SuperMap/selectors';
import ThinDrawer from '../../../../Drawer/Shared/Drawer/thin-drawer';
import { DrawProjectDrawerHeader, DrawProjectDrawerContent } from '../../../../Drawer/drawer-control';
import GeoJSONProject, { Project } from '../../Project/geojson-project';
import { DrawPage, DrawPageMapLayer, parseSatellite, parseMapLayers } from '../draw-story';
import StoryBuilderAddPage from './story-builder-add-page';
import StoryBuilderProjectDetail from './story-builder-project-detail';
import StoryBuilderPageItem from './story-builder-page-item';
import StoryBuilderSaveButton from './story-builder-save-button';

import { setArrowsAction } from '../../../../../store/Annotations/Arrow/actions';
import { selectArrows } from '../../../../../store/Annotations/Arrow/selectors';
import { setCirclesAction } from '../../../../../store/Annotations/Circle/actions';
import { selectCircles } from '../../../../../store/Annotations/Circle/selectors';
import { setCoordinatesAction } from '../../../../../store/Annotations/Coordinate/actions';
import { selectCoordinates } from '../../../../../store/Annotations/Coordinate/selectors';
import { setFreehandDrawsAction } from '../../../../../store/Annotations/Freehand/actions';
import { selectFreehandDraws } from '../../../../../store/Annotations/Freehand/selectors';
import { setImagesAction } from '../../../../../store/Annotations/Images/actions';
import { selectImages } from '../../../../../store/Annotations/Images/selectors';
import { setMarkersAction } from '../../../../../store/Annotations/Marker/actions';
import { selectMarkers } from '../../../../../store/Annotations/Marker/selectors';
import { setMilitaryMarkersAction } from '../../../../../store/Annotations/MilitaryMarker/actions';
import { selectMilitaryMarkers } from '../../../../../store/Annotations/MilitaryMarker/selectors';
import { setPolylinesAction } from '../../../../../store/Annotations/Path/actions';
import { selectPolylines } from '../../../../../store/Annotations/Path/selectors';
import { setPolygonsAction } from '../../../../../store/Annotations/Polygon/actions';
import { selectPolygons } from '../../../../../store/Annotations/Polygon/selectors';
import { setRectanglesAction } from '../../../../../store/Annotations/Rectangle/actions';
import { selectRectangles } from '../../../../../store/Annotations/Rectangle/selectors';
import { selectTextBoxes } from '../../../../../store/Annotations/TextBox/selectors';
import { setSelectedAnnotationAction } from '../../../../../store/Annotations/actions';
import { selectMapBounds } from '../../../../../store/App/selectors';
import { selectDrawPage } from '../../../../../store/Map/DrawStory/selectors';

import Arrow from '../../Arrow/arrow';
import Circle from '../../Circle/circle';
import Coordinate from '../../Coordinate/coordinate';
import FreehandPolyline from '../../FreehandPolyline/freehand-polyline';
import Image from '../../ImageTool/image';
import Marker from '../../Marker/marker';
import MilitaryMarker from '../../MilitaryMarker/military-marker';
import PolygonPath from '../../Polygon/polygon';
import Polyline from '../../Polyline/polyline';
import Rectangle from '../../Rectangle/rectangle';
import TextBox from '../../Text/textbox';

import ApiDraw from '../../../../../api/api-draw';
import ApiListings from '../../../../../api/api-listings';
import { arraymove } from '../../../../../lib/array-util';
import { setTextBoxesAction } from '../../../../../store/Annotations/TextBox/actions';
import { Annotation } from '../../../../../store/Annotations/reducer';
import { actionFlyTo } from '../../../../../store/App/actions';
import { selectDrawStoryId } from '../../../../../store/Map/DrawStory/selectors';
import { actionActiveMapCleared, actionActiveMapFetchById } from '../../../../../store/Map/SuperMap/actions';
import { actionSetTileLayerOpacity } from '../../../../../store/Map/TileLayer/actions';
import { selectTileLayersOpacity } from '../../../../../store/Map/TileLayer/selectors';
import ProjectAccess from '../../Project/project-access';
import { handleCopyingProjectAnnotations } from '../../FillPattern/pattern-util';
import Analytics from '../../../../../lib/user-analytics';
import { useBasemap } from '../../../../../store/Map/Basemap/use-basemap';
import {
    actionSentinelSelectAOI,
    actionSentinelSelectFeature,
    actionSentinelResetSelected,
    actionSentinelSelectFeatureOpacity,
} from '../../../../../store/Map/Sentinel/actions';
import {
    selectSentinelSelectedFeature,
    selectSentinelSelectedFeatureOpacity,
} from '../../../../../store/Map/Sentinel/selectors';

const StoryBuilderSidedrawer = () => {
    const { basemap, setBasemap } = useBasemap();

    const dispatch = useDispatch();
    const activeMap = useSelector(selectActiveAllMap);
    const tileLayersOpacity = useSelector(selectTileLayersOpacity);
    const activeSentinelFeature = useSelector(selectSentinelSelectedFeature);
    const activeSentinelFeatureOpacity = useSelector(selectSentinelSelectedFeatureOpacity);

    const markers = useSelector(selectMarkers);
    const polylines = useSelector(selectPolylines);
    const polygons = useSelector(selectPolygons);
    const circles = useSelector(selectCircles);
    const rectangles = useSelector(selectRectangles);
    const freehandDraws = useSelector(selectFreehandDraws);
    const arrows = useSelector(selectArrows);
    const images = useSelector(selectImages);
    const textBoxes = useSelector(selectTextBoxes);
    const coordinates = useSelector(selectCoordinates);
    const militarySymbols = useSelector(selectMilitaryMarkers);
    const drawStoryId = useSelector(selectDrawStoryId);
    const mapBounds = useSelector(selectMapBounds);

    const createdDrawPage = useSelector(selectDrawPage);

    const setMarkers = (markers: Marker[]) => dispatch(setMarkersAction(markers));
    const setPolylines = (polylines: Polyline[]) => dispatch(setPolylinesAction(polylines));
    const setPolygons = (polygons: PolygonPath[]) => dispatch(setPolygonsAction(polygons));
    const setCircles = (circles: Circle[]) => dispatch(setCirclesAction(circles));
    const setRectangles = (rectangles: Rectangle[]) => dispatch(setRectanglesAction(rectangles));
    const setFreehandDraws = (freehandDraws: FreehandPolyline[]) => dispatch(setFreehandDrawsAction(freehandDraws));
    const setArrows = (arrows: Arrow[]) => dispatch(setArrowsAction(arrows));
    const setImages = (images: Image[]) => dispatch(setImagesAction(images));
    const setCoordinates = (coordinates: Coordinate[]) => dispatch(setCoordinatesAction(coordinates));
    const setTextBoxes = (textBoxes: TextBox[]) => dispatch(setTextBoxesAction(textBoxes));
    const setMilitaryMarkers = (militaryMarkers: MilitaryMarker[]) =>
        dispatch(setMilitaryMarkersAction(militaryMarkers));
    const setSelectedAnnotation = (annotation: Annotation | undefined) =>
        dispatch(setSelectedAnnotationAction(annotation));

    const emptyProject: Project = {
        markers: [],
        polylines: [],
        polygons: [],
        circles: [],
        rectangles: [],
        freehandDraws: [],
        arrows: [],
        images: [],
        textBoxes: [],
        coordinates: [],
        militaryMarkers: [],
    };

    const emptyDrawPageMapLayer: DrawPageMapLayer[] = [];
    if (activeMap) {
        emptyDrawPageMapLayer.push({
            listingId: activeMap.id,
            title: activeMap.title,
            author: activeMap.userName,
            opacity: tileLayersOpacity[activeMap.id] ?? 1,
        });
    }
    if (activeSentinelFeature) {
        emptyDrawPageMapLayer.push({
            satelliteFeature: activeSentinelFeature,
            title: new Date(activeSentinelFeature.date).toLocaleDateString(),
            author: activeSentinelFeature.satellite,
            opacity: activeSentinelFeatureOpacity ?? 1,
        });
    }

    const emptyDrawPage: DrawPage = {
        pageTitle: 'Page 1',
        index: 0,
        id: uuidv4(),
        description: '',
        project: emptyProject,
        mapLayers: emptyDrawPageMapLayer,
        viewportLocked: false,
        allViewportsLocked: false,
        activeBaseMap: basemap,
    };

    const [drawProject, setDrawProject] = useState<ProjectResponse>();
    const [drawPages, setDrawPages] = useState<DrawPage[]>([createdDrawPage ?? emptyDrawPage]);
    const [selectedDrawPage, setSelectedDrawPage] = useState<DrawPage>(createdDrawPage ?? emptyDrawPage);
    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (basemap) {
            const currentPage = drawPages.find((p) => p.id === selectedDrawPage.id) || selectedDrawPage;
            if (currentPage.activeBaseMap === basemap) return;
            const updatedPage = { ...currentPage, activeBaseMap: basemap };
            setDrawPages((prev) => prev.map((p) => (p.id === currentPage.id ? updatedPage : p)));
        }
    }, [basemap, drawPages, selectedDrawPage]);

    // Update the current page when the annotations change
    useEffect(() => {
        const project: Project = {
            ...selectedDrawPage.project,
            markers: markers,
            polylines: polylines,
            polygons: polygons,
            circles: circles,
            rectangles: rectangles,
            freehandDraws: freehandDraws,
            arrows: arrows,
            images: images,
            textBoxes: textBoxes,
            activeMap: activeMap?.id,
            coordinates: coordinates,
            militaryMarkers: militarySymbols,
        };
        const currentPage = drawPages.find((p) => p.id === selectedDrawPage.id) || selectedDrawPage;
        const updatedPage = { ...currentPage, project: project };
        setDrawPages((prev) => prev.map((p) => (p.id === currentPage.id ? updatedPage : p)));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        markers,
        polylines,
        polygons,
        circles,
        rectangles,
        freehandDraws,
        arrows,
        images,
        textBoxes,
        coordinates,
        militarySymbols,
    ]);

    // Reset the project when unmounted otherwise the previous project will be used when the component is mounted again
    useEffect(() => {
        return () => {
            setSelectedDrawPage(emptyDrawPage);
            setDrawPages([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Update the map when the selected page changes
    useEffect(() => {
        dispatch(actionActiveMapCleared());
        dispatch(actionSentinelResetSelected());

        if (selectedDrawPage.project) {
            setArrows(selectedDrawPage.project.arrows);
            setMarkers(selectedDrawPage.project.markers);
            setPolylines(selectedDrawPage.project.polylines);
            setPolygons(selectedDrawPage.project.polygons);
            setCircles(selectedDrawPage.project.circles);
            setRectangles(selectedDrawPage.project.rectangles);
            setFreehandDraws(selectedDrawPage.project.freehandDraws);
            setImages(selectedDrawPage.project.images);
            setTextBoxes(selectedDrawPage.project.textBoxes);
            setCoordinates(selectedDrawPage.project.coordinates);
            setMilitaryMarkers(selectedDrawPage.project.militaryMarkers);
            setSelectedAnnotation(undefined);
        }

        if (selectedDrawPage.mapLayers) {
            selectedDrawPage.mapLayers.forEach((mapLayer) => {
                if (mapLayer.listingId) {
                    dispatch(actionActiveMapFetchById(mapLayer.listingId, undefined, true));
                    if (mapLayer.opacity !== undefined) {
                        dispatch(actionSetTileLayerOpacity(mapLayer.listingId, mapLayer.opacity));
                    }
                }
                if (mapLayer.satelliteFeature) {
                    const satelliteFeature = parseSatellite(mapLayer);
                    dispatch(actionSentinelSelectAOI(satelliteFeature.bbox));
                    dispatch(actionSentinelSelectFeature(satelliteFeature));
                    dispatch(actionSentinelSelectFeatureOpacity(mapLayer.opacity));
                }
            });
        }

        if (selectedDrawPage.project && selectedDrawPage.project.viewportBounds) {
            dispatch(actionFlyTo(selectedDrawPage.project.viewportBounds as L.LatLngBounds, undefined, true));
        }

        if (selectedDrawPage.activeBaseMap) {
            setBasemap(selectedDrawPage.activeBaseMap);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDrawPage, selectedDrawPage.project?.viewportBounds]);

    // Load the selected map from the route /map/edit/{id}
    useEffect(() => {
        if (drawStoryId) {
            ApiDraw.getDrawProject(Number(drawStoryId))
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .then((res: any) => {
                    Analytics.Event('Draw Tools - Project', `Fetched story project ${drawStoryId}`);
                    setDrawProject(res as ProjectResponse);
                    if (res.data && res.data.pages) {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        const pagesRaw: any[] = res.data.pages;
                        const pages: DrawPage[] = pagesRaw.map((page, index) => {
                            const project = GeoJSONProject.import(JSON.stringify(page));

                            const mapLayers =
                                page.mapLayers?.map((mapLayer) => parseMapLayers(mapLayer)) ??
                                ([] as DrawPageMapLayer[]);

                            if (page.map) {
                                mapLayers.push({
                                    listingId: page.map.id,
                                    title: page.map.title,
                                    author: page.map.author,
                                    opacity: page.map.opacity ?? 1,
                                });
                            }

                            const drawPage: DrawPage = {
                                projectTitle: res.title,
                                pageTitle: page.pageTitle || page.title,
                                index: index,
                                id: page.project.id,
                                description: page.description || res.description,
                                project: project,
                                mapLayers: mapLayers,
                                viewportLocked: page.viewportLocked,
                                allViewportsLocked: page.allViewportsLocked || false,
                                activeBaseMap: page.activeBaseMap,
                            };
                            return drawPage;
                        });
                        setDrawPages(pages);
                        setSelectedDrawPage(pages[0]);
                    } else if (res.data) {
                        const mapLayers =
                            res.data?.project?.mapLayers.map((mapLayer) => parseMapLayers(mapLayer)) ??
                            ([] as DrawPageMapLayer[]);

                        if (res.mapIds && res.mapIds.length) {
                            mapLayers.push({
                                listingId: res.mapIds[0],
                                title: 'Map Layer',
                                author: 'Soar User',
                                opacity: 1,
                            });
                        }

                        const drawPage: DrawPage = {
                            pageTitle: res.title,
                            index: 0,
                            id: res.id,
                            description: res.description,
                            project: GeoJSONProject.import(JSON.stringify(res.data)),
                            mapLayers: mapLayers,
                            viewportLocked: res.viewportLocked,
                            allViewportsLocked: res.allViewportsLocked || false,
                            activeBaseMap: res.activeBaseMap,
                        };
                        setDrawPages([drawPage]);
                        setSelectedDrawPage(drawPage);
                    }
                })
                .catch((error) => {
                    setError(error.message);
                });
        }
    }, [drawStoryId]);

    const handleDeletePage = (page: DrawPage) => {
        Analytics.Event('Draw Tools - Project', 'Clicked delete page');
        const currentPagePos = drawPages.findIndex((p) => p.id === page.id);
        const nextPagePos = currentPagePos === 0 ? 1 : currentPagePos - 1;
        const nextPage = drawPages[nextPagePos];
        setDrawPages(drawPages.filter((p) => p.id !== page.id));
        setSelectedDrawPage(nextPage);
    };

    const handleAddNewPage = () => {
        Analytics.Event('Draw Tools - Project', 'Clicked add new page');
        const project = drawPages[drawPages.length - 1].allViewportsLocked
            ? {
                  ...emptyProject,
                  viewportBounds: drawPages[drawPages.length - 1].project.viewportBounds,
              }
            : {
                  ...emptyProject,
                  viewportBounds: mapBounds as L.LatLngBounds,
              };

        const newPage: DrawPage = {
            pageTitle: `Page ${drawPages.length + 1}`,
            index: drawPages.length,
            id: uuidv4(),
            description: '',
            project: project,
            viewportLocked: false,
            allViewportsLocked: drawPages[drawPages.length - 1].allViewportsLocked || false,
            activeBaseMap: basemap,
        };
        setDrawPages([...drawPages, newPage]);
        setSelectedDrawPage(newPage);
    };

    const handleCopyPage = () => {
        Analytics.Event('Draw Tools - Project', 'Clicked copy page');
        const currentPage = drawPages.find((p) => p.id === selectedDrawPage.id) || selectedDrawPage;
        const titleCopy = currentPage?.pageTitle?.includes('(copy)')
            ? currentPage.pageTitle
            : `${currentPage.pageTitle} (copy)`;
        const updateUniqueProjectAnnotations = handleCopyingProjectAnnotations(currentPage.project);

        const projectCopy = updateUniqueProjectAnnotations ? updateUniqueProjectAnnotations : currentPage.project;

        const newPage: DrawPage = {
            pageTitle: titleCopy,
            index: drawPages.length,
            id: uuidv4(),
            description: currentPage.description,
            project: {
                ...projectCopy,
                viewportBounds:
                    currentPage.viewportLocked || currentPage.allViewportsLocked
                        ? projectCopy.viewportBounds
                        : (mapBounds as L.LatLngBounds),
            },
            mapLayers: currentPage.mapLayers,
            viewportLocked: currentPage.viewportLocked,
            allViewportsLocked: currentPage.allViewportsLocked,
            activeBaseMap: currentPage.activeBaseMap,
        };
        setDrawPages([...drawPages, newPage]);
        setSelectedDrawPage(newPage);
    };

    const handleUpdateMapLayerFromPage = (updatedMapLayer: DrawPageMapLayer) => {
        Analytics.Event(
            'Draw Tools - Project',
            'Clicked update map layer from page',
            updatedMapLayer.listingId ?? updatedMapLayer.satelliteFeature?.previewUrl ?? ''
        );

        const currentPage = drawPages.find((p) => p.id === selectedDrawPage.id) || selectedDrawPage;

        const mapLayers = currentPage.mapLayers?.map((mapLayer) => {
            if (mapLayer.listingId && updatedMapLayer.listingId && mapLayer.listingId === updatedMapLayer.listingId) {
                return updatedMapLayer;
            }
            if (
                mapLayer.satelliteFeature &&
                updatedMapLayer.satelliteFeature &&
                mapLayer.satelliteFeature.previewUrl === updatedMapLayer.satelliteFeature.previewUrl
            ) {
                return updatedMapLayer;
            }
            return mapLayer;
        });

        const project = currentPage.allViewportsLocked
            ? currentPage.project
            : {
                  ...currentPage.project,
                  viewportBounds: currentPage.viewportLocked
                      ? currentPage.project.viewportBounds
                      : (mapBounds as L.LatLngBounds),
              };

        const updatedPage = { ...currentPage, project, mapLayers };

        const updatedPages = drawPages.map((p) => (p.id === currentPage.id ? updatedPage : p));

        setDrawPages(updatedPages as DrawPage[]);
        setSelectedDrawPage(updatedPage as DrawPage);
    };

    const handleAddMapToPage = (id: number) => {
        ApiListings.getListing(id).then((listing) => {
            const currentPage = drawPages.find((p) => p.id === selectedDrawPage.id) || selectedDrawPage;

            const mapLayer: DrawPageMapLayer = {
                listingId: listing.id,
                title: listing.title,
                author: listing.userName || 'Soar User',
                opacity: 1,
            };

            /* // code for multiple layers support
            const mapLayers = currentPage.mapLayers ?? ([] as DrawPageMapLayer[]);
            mapLayers.push(satelliteMapLayer);*/

            const mapLayers: DrawPageMapLayer[] = [mapLayer]; // single mapLayer support

            const project = currentPage.allViewportsLocked
                ? currentPage.project
                : {
                      ...currentPage.project,
                      viewportBounds: currentPage.viewportLocked
                          ? currentPage.project.viewportBounds
                          : (mapBounds as L.LatLngBounds),
                  };

            const updatedPage = {
                ...currentPage,
                project,
                mapLayers,
            };

            const updatedPages = drawPages.map((p) => (p.id === currentPage.id ? updatedPage : p));
            setDrawPages(updatedPages);
            setSelectedDrawPage(updatedPage);

            Analytics.Event('Draw Tools - Project', 'Clicked add map to page', id);
        });
    };

    const handleAddSatelliteToPage = (satelliteMapLayer: DrawPageMapLayer) => {
        const currentPage = drawPages.find((p) => p.id === selectedDrawPage.id) || selectedDrawPage;

        /* // code for multiple layers support
        const mapLayers = currentPage.mapLayers ?? ([] as DrawPageMapLayer[]);
        mapLayers.push(satelliteMapLayer);*/

        const mapLayers: DrawPageMapLayer[] = [satelliteMapLayer]; // single mapLayer support

        const project = currentPage.allViewportsLocked
            ? currentPage.project
            : {
                  ...currentPage.project,
                  viewportBounds: currentPage.viewportLocked
                      ? currentPage.project.viewportBounds
                      : (mapBounds as L.LatLngBounds),
              };

        const updatedPage = { ...currentPage, project, mapLayers };
        const updatedPages = drawPages.map((p) => (p.id === currentPage.id ? updatedPage : p));
        setDrawPages(updatedPages);
        setSelectedDrawPage(updatedPage);

        const parsedSatellite = parseSatellite(satelliteMapLayer);

        dispatch(actionSentinelSelectAOI(parsedSatellite.bbox));
        dispatch(actionSentinelSelectFeature(parsedSatellite));

        Analytics.Event(
            'Draw Tools - Project',
            'Clicked add sentinel to page',
            satelliteMapLayer.satelliteFeature?.previewUrl
        );
    };

    const handleDeleteMapLayerFromPage = (deletedMapLayer: DrawPageMapLayer) => {
        const currentPage = drawPages.find((p) => p.id === selectedDrawPage.id) || selectedDrawPage;
        const updatedPage = {
            ...currentPage,
            mapLayers: currentPage.mapLayers?.filter((existingMapLayer) => {
                if (deletedMapLayer.listingId) {
                    if (!existingMapLayer.listingId) {
                        return true;
                    }

                    if (existingMapLayer.listingId && existingMapLayer.listingId !== deletedMapLayer.listingId) {
                        return true;
                    }
                }

                if (deletedMapLayer.satelliteFeature) {
                    if (!existingMapLayer.satelliteFeature) {
                        return true;
                    }

                    if (
                        existingMapLayer.satelliteFeature &&
                        existingMapLayer.satelliteFeature.downloadUrl !== deletedMapLayer.satelliteFeature.downloadUrl
                    ) {
                        return true;
                    }
                }

                return false;
            }),
        };

        const updatedPages = drawPages.map((p) => (p.id === selectedDrawPage.id ? updatedPage : p));
        setDrawPages(updatedPages as DrawPage[]);

        if (deletedMapLayer.satelliteFeature) {
            Analytics.Event(
                'Draw Tools - Project',
                'Clicked delete map layer from page',
                deletedMapLayer?.satelliteFeature.previewUrl || ''
            );
            dispatch(actionSentinelResetSelected());
        }
        if (deletedMapLayer.listingId) {
            Analytics.Event(
                'Draw Tools - Project',
                'Clicked delete map layer from page',
                deletedMapLayer?.listingId || ''
            );
            dispatch(actionActiveMapCleared());
        }
    };

    const handleSetViewport = (locked: boolean) => {
        Analytics.Event('Draw Tools - Project', `Clicked set viewport`);
        const currentPage = drawPages.find((p) => p.id === selectedDrawPage.id) || selectedDrawPage;

        const resetPages = drawPages.map((page) => ({
            ...page,
            allViewportsLocked: false,
        }));

        const updatedPage: DrawPage = {
            ...currentPage,
            project: {
                ...currentPage.project,
                viewportBounds: mapBounds as L.LatLngBounds,
            },
            viewportLocked: locked,
            allViewportsLocked: false,
        };

        const updatedPages = resetPages.map((page) => (page.id === selectedDrawPage.id ? updatedPage : page));

        setDrawPages(updatedPages);
        setSelectedDrawPage(updatedPage);
    };

    const handleSetAllPagesViewport = (allLocked: boolean) => {
        Analytics.Event('Draw Tools - Project', `Clicked set all viewports`);
        const currentPage = drawPages.find((p) => p.id === selectedDrawPage.id) || selectedDrawPage;
        const updatedPage: DrawPage = {
            ...currentPage,
            project: {
                ...currentPage.project,
                viewportBounds: mapBounds as L.LatLngBounds,
            },
            viewportLocked: false,
            allViewportsLocked: allLocked,
        };

        const updatedPages = drawPages.map((page) => ({
            ...page,
            project: {
                ...page.project,
                viewportBounds: mapBounds as L.LatLngBounds,
            },
            viewportLocked: false,
            allViewportsLocked: allLocked,
        }));

        setDrawPages(updatedPages);
        setSelectedDrawPage(updatedPage);
    };

    const handlePageOrder = (page: DrawPage, direction: 'up' | 'down') => {
        Analytics.Event('Draw Tools - Project', `Clicked to reorder pages ${direction}`);
        const index = page.index;
        const newIndex = direction === 'up' ? index - 1 : index + 1;

        if ((direction === 'up' && index > 0) || (direction === 'down' && index < drawPages.length - 1)) {
            const reorderedPages = arraymove(drawPages, index, newIndex).map((p, i) => ({
                ...p,
                index: i,
            }));

            setDrawPages(reorderedPages);
        }
    };

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) return;

        const items = Array.from(drawPages);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        const updatedPages = items.map((item, index) => ({
            ...item,
            index,
        }));
        setDrawPages(updatedPages);
    };

    const handleProjectUpdate = ({ title }: UpdateProjectMetaRequest) => {
        setDrawProject({ ...drawProject, title } as ProjectResponse);
    };

    const handleOnOpen = () => {
        const currentPage = drawPages.find((p) => p.id === selectedDrawPage.id) || selectedDrawPage;

        const project = currentPage.allViewportsLocked
            ? currentPage.project
            : {
                  ...currentPage.project,
                  viewportBounds: currentPage.viewportLocked
                      ? currentPage.project.viewportBounds
                      : (mapBounds as L.LatLngBounds),
              };

        const updatedPage: DrawPage = {
            ...currentPage,
            project,
        };

        const updatedPages = drawPages.map((page) => (page.id === selectedDrawPage.id ? updatedPage : page));

        setDrawPages(updatedPages);
        setSelectedDrawPage(updatedPage);
    };

    return (
        <React.Fragment>
            {error ? (
                <ProjectAccess
                    error={error}
                    resetError={() => {
                        Analytics.Event('Draw Tools - Project', `Clicked reset after project error`, error);
                        setError(undefined);
                    }}
                />
            ) : (
                <React.Fragment>
                    <EditBorder
                        isPageLocked={
                            selectedDrawPage.allViewportsLocked ? true : selectedDrawPage.viewportLocked || false
                        }
                    />

                    <ThinDrawer style={{ marginTop: '48px' }}>
                        <DrawProjectDrawerHeader collapsible title=" " />
                        <DrawProjectDrawerContent>
                            {drawProject && (
                                <StoryBuilderProjectDetail project={drawProject} onUpdate={handleProjectUpdate} />
                            )}
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="drawPages">
                                    {(provided) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {drawPages.map((page, index) => (
                                                <Draggable key={page.id} draggableId={page.id} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <StoryBuilderPageItem
                                                                key={page.id}
                                                                page={page}
                                                                pageNumber={drawPages.length}
                                                                isSelected={selectedDrawPage.id === page.id}
                                                                onOpen={() => handleOnOpen()}
                                                                onSelect={(page: DrawPage) => {
                                                                    if (selectedDrawPage.id !== page.id) {
                                                                        setSelectedDrawPage(page);
                                                                    }
                                                                    setSelectedAnnotation(undefined);
                                                                }}
                                                                onDelete={(page: DrawPage) => {
                                                                    handleDeletePage(page);
                                                                }}
                                                                onUpdateMapLayerFromPage={(
                                                                    mapLayer: DrawPageMapLayer
                                                                ) => {
                                                                    handleUpdateMapLayerFromPage(mapLayer);
                                                                }}
                                                                onDeleteMapLayerFromPage={(
                                                                    mapLayer: DrawPageMapLayer
                                                                ) => {
                                                                    handleDeleteMapLayerFromPage(mapLayer);
                                                                }}
                                                                onSelectMapLayer={(mapLayer: DrawPageMapLayer) => {
                                                                    if (mapLayer.satelliteFeature) {
                                                                        handleAddSatelliteToPage(mapLayer);
                                                                    } else if (mapLayer.listingId) {
                                                                        handleAddMapToPage(mapLayer.listingId);
                                                                    }
                                                                }}
                                                                onUpdatePageDescription={(description: string) => {
                                                                    const updatedPage = {
                                                                        ...page,
                                                                        description: description,
                                                                    };
                                                                    setDrawPages(
                                                                        drawPages.map((p) =>
                                                                            p.id === page.id ? updatedPage : p
                                                                        )
                                                                    );
                                                                }}
                                                                onUpdatePageTitle={(title: string) => {
                                                                    const updatedPage = { ...page, pageTitle: title };
                                                                    setDrawPages(
                                                                        drawPages.map((p) =>
                                                                            p.id === page.id ? updatedPage : p
                                                                        )
                                                                    );
                                                                }}
                                                                onSetPageViewport={(locked: boolean) =>
                                                                    handleSetViewport(locked)
                                                                }
                                                                onSetAllPagesViewport={(allLocked: boolean) =>
                                                                    handleSetAllPagesViewport(allLocked)
                                                                }
                                                                onMovePageDown={(page: DrawPage) =>
                                                                    handlePageOrder(page, 'down')
                                                                }
                                                                onMovePageUp={(page: DrawPage) =>
                                                                    handlePageOrder(page, 'up')
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <StoryBuilderAddPage onAddPage={handleAddNewPage} onCopyPage={handleCopyPage} />
                            <Divider />

                            <StoryBuilderSaveButton drawPages={drawPages} currentDrawProjectId={drawStoryId} />
                        </DrawProjectDrawerContent>
                    </ThinDrawer>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default StoryBuilderSidedrawer;

const Divider = styled.div`
    height: 1px;
    border-top: 1px solid ${(props) => props.theme.color.lightGray};
`;

const EditBorder = styled.div<{ isPageLocked: boolean }>`
    position: fixed;
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    border: ${(props) => (props.isPageLocked ? '4px solid #e55801' : '4px solid #eed923')};
    z-index: 9997;
    user-select: none;
    pointer-events: none;
`;
