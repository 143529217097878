import { combineReducers } from 'redux';
// Allowed location for using 'connected-react-router'
// eslint-disable-next-line no-restricted-imports
import { connectRouter } from 'connected-react-router';
import undoable, { excludeAction } from 'redux-undo';

import appReducer from './App/reducer';
import sentinelReducer from './Map/Sentinel/reducer';
import purchasesReducer from './Purchases/reducer';
import subDomainReducer from './Dashboard/reducer';
import sideDrawerReducer from './SideDrawer/reducer';
import mapTileLayerReducer from './Map/TileLayer/reducer';
import satelliteArchiveReducer from './Map/SatelliteArchive/reducer';
import tasksReducer from './Map/Tasks/reducer';
import favouritesReducer from './Map/Favourites/reducer';
import bookmarkReducer from './Map/BookMarks/reducer';
import subdomainTileLayerReducer from './Map/Subdomain/reducer';
import superMapReducer from './Map/SuperMap/reducer';
import userProfileReducer from './Map/UserProfile/reducer';
import uploadReducer from './Map/Uploads/reducer';
import mobileReducer from './Map/Mobile/reducer';
import searchReducer from './Search/reducer';
import socialMappingReducer from './SocialMapping/reducer';
import satelliteOverlayReducer from './Map/SatelliteOverlay/reducer';
import mapSelectionReducer from './Map/MapSelection/reducer';
import notificationReducer from './Notifications/reducer';
import basemapReducer from './Map/Basemap/reducer';
import myMapSelectionReducer from './Map/MyMapSelection/reducer';
import accountReducer from './Account/reducer';
import annotationReducer from './Annotations/reducer';
import arrowReducer from './Annotations/Arrow/reducer';
import markerReducer from './Annotations/Marker/reducer';
import militaryMarkerReducer from './Annotations/MilitaryMarker/reducer';
import pathReducer from './Annotations/Path/reducer';
import circleReducer from './Annotations/Circle/reducer';
import rectangleReducer from './Annotations/Rectangle/reducer';
import coordinateReducer from './Annotations/Coordinate/reducer';
import freehandReducer from './Annotations/Freehand/reducer';
import polygonReducer from './Annotations/Polygon/reducer';
import textboxReducer from './Annotations/TextBox/reducer';
import imageReducer from './Annotations/Images/reducer';

import drawProjectReducer from './Map/DrawProject/reducer';
import drawStoryReducer from './Map/DrawStory/reducer';

import { history } from './history';

import { actionTypes as at } from './Annotations/constants';
import { actionTypes as arrowAt } from './Annotations/Arrow/constants';
import { actionTypes as markerAt } from './Annotations/Marker/constants';
import { actionTypes as militaryMarkerAt } from './Annotations/MilitaryMarker/constants';
import { actionTypes as pathAt } from './Annotations/Path/constants';
import { actionTypes as circleAt } from './Annotations/Circle/constants';
import { actionTypes as rectangleAt } from './Annotations/Rectangle/constants';
import { actionTypes as coordinateAt } from './Annotations/Coordinate/constants';
import { actionTypes as freehandAt } from './Annotations/Freehand/constants';
import { actionTypes as polygonAt } from './Annotations/Polygon/constants';
import { actionTypes as textboxAt } from './Annotations/TextBox/constants';
import { actionTypes as imageAt } from './Annotations/Images/constants';

const combineAnnotationReducers = combineReducers({
    annotationReducer: annotationReducer,
    arrowReducer: arrowReducer,
    markerReducer: markerReducer,
    militaryMarkerReducer: militaryMarkerReducer,
    pathReducer: pathReducer,
    circleReducer: circleReducer,
    rectangleReducer: rectangleReducer,
    coordinateReducer: coordinateReducer,
    freehandReducer: freehandReducer,
    polygonReducer: polygonReducer,
    textboxReducer: textboxReducer,
    imageReducer: imageReducer,
});

const excludeActions = [
    at.SET_CURRENT_Z_INDEX,
    at.SET_SELECTED_ANNOTATION,
    arrowAt.SET_ARROWS,
    arrowAt.SET_ARROW_OPTIONS,
    pathAt.SET_POLYLINES,
    pathAt.SET_POLYLINE_OPTIONS,
    circleAt.SET_CIRCLES,
    circleAt.SET_CIRCLE_OPTIONS,
    rectangleAt.SET_RECTANGLES,
    rectangleAt.SET_RECTANGLE_OPTIONS,
    coordinateAt.SET_COORDINATES,
    freehandAt.SET_FREEHAND_DRAWS,
    freehandAt.SET_FREEHAND_DRAW_OPTIONS,
    polygonAt.SET_POLYGONS,
    polygonAt.SET_POLYGON_OPTIONS,
    markerAt.SET_MARKERS,
    militaryMarkerAt.SET_MILITARY_MARKERS,
    textboxAt.SET_TEXT_BOXES,
    imageAt.SET_IMAGES,
];

const UNDO_REDO_LIMIT = 50;
const UNDO_REDO_DEBUG = false;
const undoableAnnotationReducer = undoable(combineAnnotationReducers, {
    limit: UNDO_REDO_LIMIT,
    debug: UNDO_REDO_DEBUG,
    filter: excludeAction(excludeActions),
});

export const state = combineReducers({
    router: connectRouter(history),
    appDomain: appReducer,
    accountDomain: accountReducer,
    subDomain: subDomainReducer,
    sentinelDomain: sentinelReducer,
    purchasesDomain: purchasesReducer,
    sideDrawerDomain: sideDrawerReducer,
    mapTileLayerDomain: mapTileLayerReducer,
    satelliteArchiveDomain: satelliteArchiveReducer,
    tasksDomain: tasksReducer,
    favouritesDomain: favouritesReducer,
    bookmarkDomain: bookmarkReducer,
    subdomainTileLayerDomain: subdomainTileLayerReducer,
    superMapDomain: superMapReducer,
    userProfileDomain: userProfileReducer,
    uploadDomain: uploadReducer,
    mobileDomain: mobileReducer,
    searchDomain: searchReducer,
    socialMappingDomain: socialMappingReducer,
    satelliteOverlayDomain: satelliteOverlayReducer,
    mapSelectionDomain: mapSelectionReducer,
    myMapSelectionDomain: myMapSelectionReducer,
    notificationDomain: notificationReducer,
    basemapDomain: basemapReducer,
    annotationDomain: undoableAnnotationReducer,
    drawProjectDomain: drawProjectReducer,
    drawStoryDomain: drawStoryReducer,
});

export type AppState = ReturnType<typeof state>;

export interface Action {
    type: string;
    payload?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}
