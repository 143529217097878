import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

import ArrowControl from './Arrow/arrow-control';
import CircleControl from './Circle/circle-control';
import CoordinateControl from './Coordinate/coordinate-control';
import FreehandPolylineControl from './FreehandPolyline/freehand-polyline-control';
import ImageControl from './ImageTool/image-control';
import MarkerControl from './Marker/marker-control';
import MilitaryMarkerControl from './MilitaryMarker/military-marker-control';
import PolygonControl from './Polygon/polygon-control';
import PolylineControl from './Polyline/polyline-control';
import RectangleControl from './Rectangle/rectangle-control';
import TextControl from './Text/text-control';

import Analytics from '../../../lib/user-analytics';

import { actionSetDisplayAnnotations } from '../../../store/SocialMapping/actions';
import { selectActiveAnnotationString } from '../../../store/SocialMapping/selectors';

import FreehandPolygonControl from './FreehandPolygon/freehand-polygon-control';
import DeprecatedAnnotations from './Project/deprecated-annotations-to-project';
import GeoJSONProject, { Project } from './Project/geojson-project';
import OpenDrawToolsButton from './open-draw-tools-button';
import { useDeleteKeyToRemoveAnnotation } from './use-delete-key-to-remove-annotation';
import { useSelectAnnotation } from './use-select-annotation';
import useShareLinkAnnotations from './use-share-link-annotations';
import { v4 as uuidv4 } from 'uuid';
import UriHelper from '../../../lib/uri-helper';
import {
    actionClearDrawStory,
    actionSetDrawPage,
    actionSetDrawProject,
    actionSetDrawStoryBuilderActive,
} from '../../../store/Map/DrawStory/actions';
import { selectActiveAllMap } from '../../../store/Map/SuperMap/selectors';
import { SideDrawerMode } from '../../../store/SideDrawer/model';
import { useProjectAnnotationHandler } from './Project/use-project-annotation-handler';
import { DrawPage, DrawPageMapLayer, DrawProject } from './StoryMaps/draw-story';
import AnnotationsExit from './annotations-exit';
import './annotations.css';
import useAnnotationBuildLock from './use-annotation-build-lock';
import useLayers from './use-layers';
import { useUndoRedo } from './use-undo-redo';
import { deleteAllAnnotationsAction } from '../../../store/Annotations/actions';
import { selectSideDrawerMode } from '../../../store/SideDrawer/selectors';
import { selectTileLayersOpacity } from '../../../store/Map/TileLayer/selectors';
import {
    selectSentinelSelectedFeature,
    selectSentinelSelectedFeatureOpacity,
} from '../../../store/Map/Sentinel/selectors';
import useCopyPasteAnnotation from './use-copy-paste-annotation';

interface AnnotationProps {
    editMode: boolean;
    setEditMode: (isEditMode: boolean) => void;
}

const Annotations = (props: AnnotationProps) => {
    const map = useMap();
    const dispatch = useDispatch();

    const commentAnnotations = useSelector(selectActiveAnnotationString);
    const activeMap = useSelector(selectActiveAllMap);
    const tileLayersOpacity = useSelector(selectTileLayersOpacity);
    const sideDrawerMode = useSelector(selectSideDrawerMode);
    const activeSentinelFeature = useSelector(selectSentinelSelectedFeature);
    const activeSentinelFeatureOpacity = useSelector(selectSentinelSelectedFeatureOpacity);

    const { buildActive, locked } = useAnnotationBuildLock();
    const { handleSetProjectAnnotations } = useProjectAnnotationHandler();

    useShareLinkAnnotations();
    useDeleteKeyToRemoveAnnotation();
    useSelectAnnotation(buildActive, props.editMode);
    useUndoRedo();
    useLayers();
    useCopyPasteAnnotation();

    // The comment system uses a deprecated annotation system. We need to convert it to the new system
    useEffect(() => {
        if (commentAnnotations) {
            const annotations = DeprecatedAnnotations.exportToGeoJSONProject(commentAnnotations);
            if (annotations) {
                Analytics.Event('Draw Tools - Project', `Loaded deprecated annotations`);
                const project = GeoJSONProject.import(annotations);
                handleSetProjectAnnotations(project);
                // Once the annotations have been passed to mapmaker we remove the stored annotations
                dispatch(actionSetDisplayAnnotations(''));
            }
        }
    }, [commentAnnotations, dispatch, handleSetProjectAnnotations]);

    // While in edit mode, add a yellow border to the map and disable double click zoom
    useEffect(() => {
        if (props.editMode) {
            map.getContainer().classList.add('annotation-mode-edit');
            map.doubleClickZoom.disable();
        } else {
            map.getContainer().classList.remove('annotation-mode-edit');
            map.doubleClickZoom.enable();
        }
    }, [map, props.editMode]);

    useEffect(() => {
        // While I would like to not do this with a dependency on the sideDrawerMode it seems to be one of the only way to
        // ensure that the draw tools are closed and removed when navigating or backing by the browser.
        // Alternative is to add it in the map-container.tsx but would prefer to keep the responsibilities separate since it
        // would still be a similar implementation since we are using state for the editMode.
        const isSoarDraw =
            sideDrawerMode === SideDrawerMode.SOAR_DRAW_EDIT || sideDrawerMode === SideDrawerMode.SOAR_DRAW_NEW;

        if (!isSoarDraw) {
            props.setEditMode(false);
            dispatch(deleteAllAnnotationsAction());
            dispatch(actionClearDrawStory());
            dispatch(actionSetDrawStoryBuilderActive(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sideDrawerMode]);

    return (
        <React.Fragment>
            <AnnotationControlContainer
                className="leaflet-snapshot-ignore"
                data-testid="annotation-control-container"
                disabled={buildActive}
            >
                <AnnotationFlexContainer>
                    <FreehandPolylineControl visible={props.editMode} locked={locked} disabled={buildActive} />
                    <FreehandPolygonControl visible={props.editMode} locked={locked} disabled={buildActive} />
                    <PolygonControl visible={props.editMode} locked={locked} disabled={buildActive} />
                    <PolylineControl visible={props.editMode} locked={locked} disabled={buildActive} />
                    <ArrowControl visible={props.editMode} locked={locked} disabled={buildActive} />
                    <CircleControl visible={props.editMode} locked={locked} disabled={buildActive} />
                    <RectangleControl visible={props.editMode} locked={locked} disabled={buildActive} />
                    <TextControl visible={props.editMode} locked={locked} disabled={buildActive} />
                    <MarkerControl visible={props.editMode} locked={locked} disabled={buildActive} />
                    <MilitaryMarkerControl visible={props.editMode} locked={locked} disabled={buildActive} />
                    <CoordinateControl visible={props.editMode} locked={locked} disabled={buildActive} />
                    <ImageControl visible={props.editMode} locked={locked} disabled={buildActive} />

                    <AnnotationsExit editMode={props.editMode} setEditMode={props.setEditMode} />

                    <OpenDrawToolsButton
                        visible={!props.editMode}
                        onClick={() => {
                            Analytics.Event('Draw Tools - Project', `Clicked open draw tools`, 'PenIcon');
                            props.setEditMode(true);

                            const emptyProject: Project = {
                                arrows: [],
                                circles: [],
                                coordinates: [],
                                freehandDraws: [],
                                images: [],
                                markers: [],
                                polygons: [],
                                polylines: [],
                                rectangles: [],
                                textBoxes: [],
                                militaryMarkers: [],
                            };

                            const mapLayers: DrawPageMapLayer[] = [];
                            if (activeMap) {
                                mapLayers.push({
                                    listingId: activeMap.id,
                                    title: activeMap.title,
                                    author: activeMap.userName,
                                    opacity: tileLayersOpacity[activeMap.id] ?? 1,
                                });
                            }
                            if (activeSentinelFeature) {
                                mapLayers.push({
                                    title: new Date(activeSentinelFeature.date).toLocaleDateString(),
                                    author: activeSentinelFeature.satellite,
                                    satelliteFeature: activeSentinelFeature,
                                    opacity: activeSentinelFeatureOpacity ?? 1,
                                });
                            }

                            const emptyPage: DrawPage = {
                                pageTitle: 'Page 1',
                                index: 0,
                                id: uuidv4(),
                                description: '',
                                project: emptyProject,
                                mapLayers,
                            };

                            const emptyDrawProject: DrawProject = {
                                title: 'My Stoary',
                                id: uuidv4(),
                                drawPages: [emptyPage],
                            };

                            dispatch(actionSetDrawStoryBuilderActive(true));
                            dispatch(actionSetDrawProject(emptyDrawProject));
                            dispatch(actionSetDrawPage(emptyPage));
                            UriHelper.navigateToDrawer(SideDrawerMode.SOAR_DRAW_NEW);
                        }}
                    />
                </AnnotationFlexContainer>
                <Tooltip
                    id="annotation-control-tooltip"
                    style={{
                        backgroundColor: 'rgba(0,0,0,0.85)',
                        color: '#ffffff',
                        fontFamily: "'Manrope', sans-serif !important",
                    }}
                />
            </AnnotationControlContainer>
            {props.editMode && <ScreenShotWaterMark id="soar-screenshot-watermark" />}
        </React.Fragment>
    );
};

export default Annotations;

const AnnotationControlContainer = styled.div<{ disabled: boolean }>`
    user-select: ${(props) => (props.disabled ? 'none' : 'auto')};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'auto')};
    position: fixed;
    width: auto;
    bottom: 30px;
    z-index: 999;
    user-select: none;
    left: 50%;
    transform: translateX(-50%);
`;

const AnnotationFlexContainer = styled.div`
    display: flex;
    justify-content: center;

    width: fit-content;
    margin: 0 auto;
`;

const ScreenShotWaterMark = styled.div`
    position: absolute;
    bottom: 5px;
    right: 5px;
    width: 130px;
    height: 24px;
    z-index: 100000;
    background-image: url('/assets/watermarks/soar-logo-watermark.png');
    background-repeat: no-repeat;
    background-size: 130px 24px;
    display: none;
`;
