import axios from 'axios';
import { isMobile } from 'react-device-detect';
import GeoJSONProject, { Project } from '../components/MapView/Annotations/Project/geojson-project';
import Constants from '../constants';
import Analytics from '../lib/user-analytics';
import Api from './api';
import { UploadCredentialsDTO } from './model';
import { OssUploader } from './oss-uploader';
import UserHelper from '../lib/user-helper';

export interface CreateProjectMetaRequest {
    title: string;
    description?: string; // TODO #7369 - description should not be required
    public: boolean;
    sharePermissions: {
        email: string;
    }[];
}

export interface CreateProjectRequest extends CreateProjectMetaRequest {
    mapIds?: number[];
    // Data is a GeoJSON FeatureCollection object but extended... wait till connected maybe..
    data: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface UpdateProjectMetaRequest {
    title: string;
    public: boolean;
    sharePermissions: {
        email: string;
    }[];
}

export interface UpdateProjectDataRequest {
    mapIds?: number[];
    data?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export type UpdateProjectRequest = UpdateProjectMetaRequest | UpdateProjectDataRequest;

export interface ProjectResponse {
    id: number;
    title?: string;
    description?: string;
    categories?: string[];
    mapIds?: number[];
    data?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
    ownerId: string;
    ownerName: string;
    ownerReputable: boolean;
    updatedAt: number;
    createdAt: number;
    sharedAt?: number;
    public: boolean;
    sharePermissions: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
    featureCount?: number;
    dataByteSize?: number;
    viewPortsWKT?: string[];
}

let CANCEL_TOKEN_PROJECT_SEARCH = axios.CancelToken.source();

export default class ApiDraw extends Api {
    public static async uploadImage(image: File): Promise<string> {
        const uploadCredentialStartTime = performance.now();
        const response = await this.axios.post('/v1/map-draw/image/upload');
        const uploadCredentialEndTime = performance.now();
        console.log(
            'Request image upload credentials: ' + (uploadCredentialEndTime - uploadCredentialStartTime) + 'ms'
        );

        const credentials: UploadCredentialsDTO = response.data;
        const attachmentOssUploader = new OssUploader(credentials);
        const path = `${credentials.path}/${image.name}`;

        const uploadStartTime = performance.now();
        await attachmentOssUploader.uploadFileToStorage(image, path);
        const uploadEndTime = performance.now();
        console.log('Upload image: ' + (uploadEndTime - uploadStartTime) + 'ms');
        const url = `https://sp.${Constants.DOMAIN}/map-draw/image/${response.data.drawImageId}`;
        return url;
    }

    public static async getProjectFromShareLink(id: number): Promise<Project> {
        const response = await this.axios.get(`/v1/map-draw/projects/${id}`);
        const project = GeoJSONProject.import(response.data.data);
        Analytics.Event('Draw Tools - Project', `Get project from share link`, id.toString());
        return project;
    }

    public static async createDrawProject(projectData: CreateProjectRequest): Promise<ProjectResponse> {
        // TODO #7369 - description should not be required but added temporarily to avoid breaking changes
        projectData.description = '';
        const response = await this.axios.post('/v1/map-draw/projects', projectData, {
            cache: false,
            errorOnInvalidAuth: true,
        });
        Analytics.Event('Draw Tools - Project', `Created draw Project`, response.data.id.toString());
        return response.data;
    }

    public static async updateDrawProject(id: number, projectData: UpdateProjectRequest): Promise<ProjectResponse> {
        const response = await this.axios.put(`/v1/map-draw/projects/${id}`, projectData, {
            cache: false,
        });
        Analytics.Event('Draw Tools - Project', `Updated share Project`, id.toString());
        return response.data;
    }

    public static async deleteDrawProject(id: number): Promise<void> {
        await this.axios.delete(`/v1/map-draw/projects/${id}`);
    }

    public static async getDrawProject(id: number): Promise<ProjectResponse> {
        if (UserHelper.getIdToken() === undefined && UserHelper.getRefreshToken()) {
            await UserHelper.refreshAuth();
        }

        const response = await this.axios.get(`/v1/map-draw/projects/${id}`, { cache: false });
        Analytics.Event('Draw Tools - Project', `Viewed project on ${isMobile ? 'mobile' : 'desktop'}`, id.toString());
        return response.data;
    }

    public static async getUserProjects(): Promise<ProjectResponse[]> {
        const response = await this.axios.get('/v1/map-draw/projects', { cache: false });
        return response.data;
    }

    public static async getSharedProjects(): Promise<ProjectResponse[]> {
        const response = await this.axios.get('/v1/map-draw/projects/shared', { cache: false });
        return response.data;
    }

    public static cancelSearchDrawProjects(cancelReason: string) {
        // Allow a reason for cancellation to be added
        CANCEL_TOKEN_PROJECT_SEARCH.cancel(cancelReason);
        // Generate a new token each time a request is cancelled;
        this.generateSearchDrawProjectsCancelToken();
    }

    private static generateSearchDrawProjectsCancelToken() {
        CANCEL_TOKEN_PROJECT_SEARCH = axios.CancelToken.source();
    }

    public static async getAndSearchProjects(
        offset: number,
        limit: number,
        searchTerm?: string,
        aoi?: string
    ): Promise<ProjectResponse[]> {
        type StoariesParams = {
            offset: string;
            limit: string;
            keywords?: string;
            aoi?: string;
        };

        const params: StoariesParams = {
            limit: limit.toString(),
            offset: offset.toString(),
        };

        if (searchTerm) {
            params.keywords = searchTerm;
        }

        if (aoi) {
            params.aoi = aoi;
        }

        return this.axios
            .get('/v1/map-draw/projects/search', {
                params,
                cancelToken: CANCEL_TOKEN_PROJECT_SEARCH.token,
                cache: false,
            })
            .then((res) => {
                return res.data;
            });
    }
}
